import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Measurement } from '@/shared/models/dto/measurement'
import { ODataFilterItem, ODataFilterObject } from '../../../shared/models/shared/odata-filter-object';
import { DateTime } from 'luxon';
import { FilterOperator } from '../../../shared/models/shared/filter-operator';
import { IRestResponseDto } from '../../../shared/models/shared/rest-response-dto';

@Component({
    components: {
        sensorChart: require('@/views/_components/device/sensor-chart.vue').default
    }
})
export default class SensorChartPeriodSelectionComponent extends AuthComponentBase {
    @Prop({ default: null }) readonly sensorId: number;
    @Prop({ default: null }) readonly deviceId: number;
    @Prop({ default: '' }) readonly sensorName: string;

    loading: boolean = false;
    selected = '';
    measurements: Measurement[] = [];

    get periods() {
        return [
            { value: 'week', text: this.t('Week') },
            { value: 'day', text: this.t('Day') },
        ];
    } 

    async mounted() {
        this.selected = 'week';
        this.loading = true;
        await this.getItems();
        this.loading = false;
    }

    async getItems() {
        let filterItems: ODataFilterItem[] = [];

        let dates = this.getFromUntilDates();
        filterItems.push({ columnName: 'timeAdded', value: DateTime.fromISO(dates.from, { zone: 'utc' }).toJSDate(), operator: FilterOperator.gt });
        filterItems.push({ columnName: 'timeAdded', value: DateTime.fromISO(dates.until, { zone: 'utc' }).toJSDate(), operator: FilterOperator.lt });

        const queryObj: ODataFilterObject = {
            filter: filterItems && filterItems.length ? filterItems : []
        };

        const query = this.queryBuilder(queryObj);

        await this.authService.get<IRestResponseDto<Measurement[]>>(
            `/api/Measurements?deviceId=${this.deviceId}&sensorId=${this.sensorId}&${query.slice(1, query.length)}`, false)
            .then((response) => this.measurements = <Measurement[]><unknown>response.content);
    }

    getFromUntilDates() {
        const startObj = DateTime.fromMillis(new Date().getTime());
        const range = this.selected;
        let result = {
            from: '',
            until: ''
        }
        result.from = startObj.minus({ hours: 24 }).toString();

        if (range === 'week') {
            result.from = startObj.minus({ days: 7 }).toString();
        } 
        result.until = startObj.toString();

        return result;
    }
}