import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DeviceBasis } from '@/shared/models/dto/devices';
import { IRestResponseDto } from '../../shared/models/shared/rest-response-dto';
import { NameValue } from '../../shared/models/dto/general';
import { DashboardItem } from '../../shared/models/dto/dashboards';

@Component({
    components: {
        errorMessage: require('@/views/_components/error-message/error-message.vue').default
    }
})
export default class SensorSelectDialogComponent extends AuthComponentBase {
    @Prop({ default: false }) readonly show: boolean;
    @Prop({ default: null }) readonly dashboardId: number;

    errors: NameValue[] = [];
    items = [];
    active: string[] = [];

    totalCount: number;
    valid: boolean = false;
    loading: boolean = false;

    @Watch('show', { immediate: true })
    async onShowChanged() {
        if (this.show) {
            this.errors = [];
            await this.getItems();
        }
    }

    close() {
        this.$emit('completed', false);
    }

    async getItems() {
        this.loading = true;

        await this.authService.get<IRestResponseDto<DeviceBasis[]>>(`/api/devices?$expand=sensors`, false).then((response) => {
            let result: DeviceBasis[] = <DeviceBasis[]><unknown>response.content;

            this.items = result ? result.map(device => {
                return {
                     id: device.name, name: device.name, children: device.sensors ? device.sensors.map(sensor => {
                        return {
                            id: `${device.id}-${sensor.id}`,
                            name: sensor.displayName
                        };
                    }) : []
                };  
            }) : [];
            
            this.totalCount = response.count;
       
            this.loading = false;
        });
    }

    @Watch('active', { immediate: true })
    onSelectedChanged() {
        this.errors = [];
    }

    async save() {
        if (this.active.length === 0) {
            this.errors = [
                {
                    name: 'NoneSelected',
                    value: this.t('NoneSelected')
                }
            ];
        }
        else {
            var idArr = this.active[0].split('-');
            const dashboardItem: DashboardItem = {
                dashboardId: this.dashboardId,
                sensorId: parseInt(idArr[1]),
                deviceId: parseInt(idArr[0]),
                deviceName: null,
                sensorName: null
            };

            await this.authService.post<DashboardItem>('/api/dashboarditems', dashboardItem).then((response) => {
                this.$emit('completed', true);
            });
        }       
    }
}